:root {
  --unit: 20px;
}

.App {
  display: block;
  flex-direction: column;
  padding: var(--unit);
  width: 100%;
  max-height: 100dvh;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

@media (max-width: 500px) {
  :root {
    --unit: 4.5vw; /* Value for screens less than 500px wide */
  }
}